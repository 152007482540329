import React, { useState } from 'react';
import { TransactionType, Product } from 'lib/enums';
import { toLocaleString } from 'lib/helpers';
import { Help, WarningOutlined } from '@material-ui/icons';
import {
  PayoutParentRowData,
  PayoutChildRowData,
  PayoutGroup
} from 'lib/types/payout';
import { getFirebaseContext } from 'utils/firebase';
import { Tooltip } from 'lib/components/Tooltip';
import {
  ArrowDownTrayIcon,
  ChevronDownIcon
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { getProductTypeBooleans } from 'lib/enums/Product';
import { safeGetOrThrow } from 'lib/safeWrappers';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnService } from 'lib/services/directory';

const TableCell: React.FC = ({ children }) => (
  <td className="py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
    {children}
  </td>
);

type PayoutParentRowProps = {
  rowData: PayoutParentRowData;
  expand: boolean;
  payoutSource: 'dwolla' | 'stripe';
  handleExpandClicked: (id: string) => void;
  handleDownloadCsvClicked: (id: string) => void;
};

function PayoutParentRow({
  rowData,
  expand,
  payoutSource,
  handleExpandClicked,
  handleDownloadCsvClicked
}: PayoutParentRowProps) {
  return (
    <tr>
      <TableCell>
        <div className="flex justify-center">
          <ChevronDownIcon
            className={`w-4 h-4 cursor-pointer ${
              !expand ? 'transform -rotate-90' : ''
            }`}
            onClick={() => handleExpandClicked(rowData.id)}
          />
        </div>
      </TableCell>
      {/* This cell is hiding for stripe papers to avoid duplicate minus (-) sign for stripe payouts.
      Stripe payout amount comes with minus (-) sign whereas Dwolla papers does not. And we have to
      manually add it. Same applies to child row. */}
      {payoutSource === 'dwolla' && (
        <TableCell>
          <p
            className={`text-right ${
              rowData.type === TransactionType.processing_fee_deduction.label ||
              rowData.type === TransactionType.refund.label ||
              (rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled')
                ? 'text-red-600'
                : 'text-black'
            }`}
          >
            {(rowData.type === TransactionType.processing_fee_deduction.label ||
              rowData.type === TransactionType.refund.label ||
              (rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled')) &&
              '-'}
          </p>
        </TableCell>
      )}
      <TableCell>
        <p
          className={`${
            rowData.type === TransactionType.processing_fee_deduction.label ||
            rowData.type === TransactionType.refund.label ||
            (rowData.type === TransactionType.payment.label &&
              rowData.status === 'cancelled')
              ? 'text-red-600'
              : 'text-black'
          }`}
        >
          {rowData.status === 'failed' && (
            <Tooltip
              classes="inline-block align-middle ml-2 font-normal"
              helpText="Payout failed. Please update account."
            >
              <WarningOutlined className="text-red-400 mr-2" />
            </Tooltip>
          )}
          {rowData.amount}
        </p>
      </TableCell>
      <TableCell>
        {/* Empty cell for child payment type */}
        <div></div>
      </TableCell>
      <TableCell>
        <p>
          {payoutSource === 'dwolla'
            ? `${toLocaleString(rowData.created)}`
            : `${rowData.created}`}
        </p>
      </TableCell>
      <TableCell>
        <p>
          {rowData.arrival_date.length > 0 && payoutSource === 'dwolla'
            ? `${toLocaleString(rowData.arrival_date)}`
            : `${rowData.arrival_date}`}
        </p>
      </TableCell>
      <TableCell>
        <div title="Download CSV">
          <ArrowDownTrayIcon
            id="payment-download"
            className="h-4 w-4 ml-4 cursor-pointer text-blue-800"
            onClick={() => handleDownloadCsvClicked(rowData.id)}
          />
        </div>
      </TableCell>
    </tr>
  );
}

type PayoutChildRowProps = {
  rowData: PayoutChildRowData;
  payoutSource: 'stripe' | 'dwolla';
};

function PayoutChildRow({ rowData, payoutSource }: PayoutChildRowProps) {
  const adType = rowData.adType?.toString() ?? Product.Notice;
  const adTypeUpperCase = adType.charAt(0).toUpperCase() + adType.slice(1);
  const {
    isNoticeProduct: adIsNotice,
    isClassifiedProduct: adIsClassified,
    isObitProduct: adIsObit
  } = getProductTypeBooleans(adType);

  const ctx = getFirebaseContext();

  const viewAd = async () => {
    const { noticeId, orderId, newspaperOrderId } = rowData;

    if (adIsClassified || adIsObit) {
      if (!orderId || !newspaperOrderId) {
        const errMsg =
          'Missing orderId or newspaperOrderId for payout child row';
        getErrorReporter().logAndCaptureError(
          ColumnService.PAYMENTS,
          new Error(errMsg),
          errMsg,
          {
            noticeId: noticeId || '',
            orderId: orderId || '',
            newspaperOrderId: newspaperOrderId || ''
          }
        );
        return;
      }
      const orderRef = ctx.ordersRef().doc(orderId);
      const [newspaperOrderSnapErr, newspaperOrderSnap] = await safeGetOrThrow(
        ctx.orderNewspaperOrdersRef(orderRef).doc(newspaperOrderId)
      );
      if (newspaperOrderSnapErr) {
        return;
      }
      const { newspaper } = newspaperOrderSnap.data();
      window.open(
        `/${adIsClassified ? 'classified' : 'obituary'}/${orderId}?activeOrg=${
          newspaper.id
        }`
      );
      return;
    }

    if (!noticeId) {
      const errMsg = 'Missing noticeId for payout child row';
      getErrorReporter().logAndCaptureError(
        ColumnService.PAYMENTS,
        new Error(errMsg),
        errMsg,
        {
          noticeId: noticeId || '',
          orderId: orderId || '',
          newspaperOrderId: newspaperOrderId || ''
        }
      );
      return;
    }
    const [noticeSnapErr, noticeSnap] = await safeGetOrThrow(
      ctx.userNoticesRef().doc(noticeId)
    );
    if (noticeSnapErr) {
      return;
    }
    const { newspaper } = noticeSnap.data();
    window.open(`/notice/${rowData.noticeId}?activeOrg=${newspaper.id}`);
  };

  const getTransactionTypePill = (rowData: PayoutChildRowData) => {
    const getTransactionTypeLabel = (rowData: PayoutChildRowData) => {
      if (rowData.type === TransactionType.processing_fee_deduction.label) {
        return 'Processing Fee';
      }
      if (
        rowData.type === TransactionType.payment.label &&
        rowData.status === 'cancelled'
      ) {
        return 'Refund';
      }
      if (rowData.type === TransactionType.payment.label) {
        return `${adTypeUpperCase} Payment`;
      }
      if (rowData.type === TransactionType.manualPayment.label) {
        return 'Manual Payment';
      }
      if (rowData.type === TransactionType.dividend.label) {
        return 'Dividend';
      }
      if (rowData.type === TransactionType.paymentReversed.label) {
        return 'Payout Reversed';
      }
      return 'Refund';
    };

    return (
      <span
        className={classNames(
          'inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold',
          {
            'bg-indigo-200 text-indigo-800':
              adIsNotice || (!adIsClassified && !adIsObit),
            'bg-orange-200 text-orange-800': adIsClassified,
            'bg-green-200 text-green-800': adIsObit
          }
        )}
      >
        {getTransactionTypeLabel(rowData)}
      </span>
    );
  };
  return (
    <tr>
      <TableCell>
        {/* Empty cell for expand icon */}
        <div></div>
      </TableCell>
      {payoutSource === 'dwolla' && (
        <TableCell>
          <p
            className={`text-right ${
              rowData.type === TransactionType.processing_fee_deduction.label ||
              rowData.type === TransactionType.refund.label ||
              (rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled')
                ? 'text-red-600'
                : 'text-black'
            }`}
          >
            {(rowData.type === TransactionType.processing_fee_deduction.label ||
              rowData.type === TransactionType.refund.label ||
              (rowData.type === TransactionType.payment.label &&
                rowData.status === 'cancelled')) &&
              '-'}
          </p>
        </TableCell>
      )}
      <TableCell>
        <p
          className={`${
            rowData.type === TransactionType.processing_fee_deduction.label ||
            rowData.type === TransactionType.refund.label ||
            rowData.type === TransactionType.paymentReversed.label ||
            (rowData.type === TransactionType.payment.label &&
              rowData.status === 'cancelled')
              ? 'text-red-600'
              : 'text-black'
          }`}
        >
          {rowData.amount}
        </p>
      </TableCell>
      <TableCell>
        <div>
          {getTransactionTypePill(rowData)}
          {rowData.type === TransactionType.processing_fee_deduction.label && (
            <Tooltip
              classes="inline-block align-middle ml-2 font-normal"
              helpText="This fee is due to Column for a notice paid outside the platform."
            >
              <Help fontSize="small" className="p-0.5 pt-0"></Help>
            </Tooltip>
          )}
          {rowData.type === TransactionType.manualPayment.label &&
            rowData.reason && (
              <Tooltip
                classes="inline-block align-middle ml-2 font-normal"
                helpText={rowData.reason}
                position={'right'}
              >
                <Help fontSize="small" className="p-0.5 pt-0"></Help>
              </Tooltip>
            )}
          {rowData.transactionTypeValue === TransactionType.feeRefund.key && (
            <Tooltip
              classes="inline-block align-middle ml-2 font-normal"
              helpText="This is a refund for a previously deducted processing fee, due to cancelling an invoice paid outside Column."
            >
              <Help fontSize="small" className="p-0.5 pt-0"></Help>
            </Tooltip>
          )}
        </div>
      </TableCell>
      <TableCell>
        {/* Empty cell for created date */}
        <div></div>
      </TableCell>
      <TableCell>
        {/* Empty cell for arrived date */}
        <div></div>
      </TableCell>
      <TableCell>
        {rowData.type === TransactionType.dividend.label ? (
          <button
            type="button"
            className="px-4 py-1 bg-white box-border rounded border border-blue-600 not-italic font-semibold text-xs flex text-blue-600"
            onClick={() => window.open(rowData.receipt)}
          >
            COLUMN PAYOUT
          </button>
        ) : ![
            TransactionType.manualPayment.label,
            TransactionType.paymentReversed.label
          ].includes(rowData.type) ? (
          <button
            type="button"
            className={`${
              !rowData.noticeId
                ? 'cursor-not-allowed pointer-events-none opacity-50'
                : 'hover:bg-gray-50'
            } px-4 py-1 bg-white box-border rounded border border-blue-600 not-italic font-semibold text-xs text-blue-600`}
            onClick={() => viewAd()}
          >
            View {adTypeUpperCase}
          </button>
        ) : (
          <></>
        )}
      </TableCell>
    </tr>
  );
}

type PayoutGroupRowsProps = {
  group: PayoutGroup;
  payoutSource: 'stripe' | 'dwolla';
  handleDownloadCsvClicked: (id: string) => void;
};
function PayoutGroupRows({
  group,
  payoutSource,
  handleDownloadCsvClicked
}: PayoutGroupRowsProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <PayoutParentRow
        rowData={group.parent}
        expand={expanded}
        payoutSource={payoutSource}
        handleExpandClicked={() => setExpanded(!expanded)}
        handleDownloadCsvClicked={handleDownloadCsvClicked}
      />
      {expanded &&
        group.children.map(child => (
          <PayoutChildRow
            key={child.id}
            rowData={child}
            payoutSource={payoutSource}
          />
        ))}
    </>
  );
}

export default PayoutGroupRows;
