import { CheckIcon } from '@heroicons/react/24/outline';

type ColorSwatchProps = {
  color: string;
  checked: boolean;
  onClick: () => void;
};

function ColorSwatch({ color, checked, onClick }: ColorSwatchProps) {
  return (
    <label
      htmlFor={`${color}-option`}
      className="cursor-pointer h-8 w-8 border-4 border-transparent hover:border-white hover:shadow-inner text-white"
      style={{ backgroundColor: color }}
    >
      {checked && <CheckIcon style={{ filter: 'drop-shadow(0 0 0 black)' }} />}
      <input
        id={`${color}-option`}
        className="absolute opacity-0"
        type="radio"
        checked={checked}
        onChange={onClick}
      />
    </label>
  );
}

export default ColorSwatch;
