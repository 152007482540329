import { FormatColorFillOutlined } from '@material-ui/icons';
import { Popover } from 'lib/components/Popover';
import { Tooltip } from 'lib/components/Tooltip';
import ColorSwatchPanel from '../ColorSwatchPanel';

type BackgroundColorButtonProps = {
  value: string;
  onChange: (newValue: string) => void;
};

function BackgroundColorButton({
  value,
  onChange
}: BackgroundColorButtonProps) {
  return (
    <Tooltip helpText="Background color">
      <Popover
        id="background-color-popover"
        activator={
          <div className="flex items-center p-2 hover:bg-column-gray-200 rounded-md">
            <FormatColorFillOutlined />
          </div>
        }
        popoverType="menu"
      >
        <ColorSwatchPanel value={value} onChange={onChange} />
        <style>
          {`
        #background-color-popover-toggle path:last-child {
          fill: ${value};
          fill-opacity: 1;
        }

        .MuiSvgIcon-root {
          width: 20px;
          height: 20px;
        }
        `}
        </style>
      </Popover>
    </Tooltip>
  );
}

export default BackgroundColorButton;
