import { Product } from 'lib/enums';

export const ALL_PRODUCT_TYPES_STRING = 'all';

export const PAYOUT_ADTYPE_OPTIONS = {
  [ALL_PRODUCT_TYPES_STRING]: 'All Ad Types',
  [Product.Notice]: 'Notices',
  [Product.Classified]: 'Classifieds',
  [Product.Obituary]: 'Obituaries'
};

export const ADTYPE_FILTERS = Object.keys(PAYOUT_ADTYPE_OPTIONS);
type PayoutAdtypeOptions = typeof ADTYPE_FILTERS[number];

export interface PayoutsFilterValue {
  adType: PayoutAdtypeOptions | undefined;
}
