import moment from 'moment';
import {
  DataWithNoticeType,
  getNoticeTypeFromNoticeData,
  isNotNull,
  lastNoticePublicationDate
} from '../helpers';
import { AffidavitReconciliationSettings } from '../types/organization';
import { getAffidavitSettingsForNotice } from '../pricing/affidavits';
import {
  ENoticeDraft,
  MailDelivery,
  EFirebaseContext,
  ESnapshotExists,
  EOrganization,
  ESnapshot,
  ENotice,
  ERef,
  FirebaseTimestamp,
  exists
} from '../types';
import { SearchableNoticeRecordFilter } from '../types/searchable';
import { NotarizationPrecondition, NoticeStatusType, State } from '../enums';
import { getErrorReporter } from '../utils/errors';
import { getOrThrow } from '../utils/refs';
import {
  AAPendingQueryNotice,
  DigitalSigNotarizationNotice,
  FastWetSigNotarizationNotice,
  NoticePreconditionStatus,
  NoticePreconditionStatuses
} from './types';
import { UserNoticeModel } from '../model/objects/userNoticeModel';
import {
  NotarizationPreconditionEnum,
  NotarizationPreconditionKey,
  getTypedNotarizationPreconditionKey
} from '../enums/NotarizationPreconditions';
import { getModelFromSnapshot } from '../model';
import {
  getDateStringForDateInTimezone,
  getPublicationTimestampForElasticQuery
} from '../utils/dates';
import { getMailDataFromNoticeOrDraft } from '../mail';
import { RunModel } from '../model/objects/runModel';
import { noticeIsSubmitted } from '../notice/helpers';
import { EEditionUploadMethod } from '../types/eedition';

export const isAffidavitDisabled = (
  noticeTypeData: DataWithNoticeType,
  newspaper: ESnapshot<EOrganization>
) => {
  const customNotice = getNoticeTypeFromNoticeData(noticeTypeData, newspaper, {
    skipDisplayType: true
  });
  return (
    !!newspaper.data()?.affidavitDisabled || !!customNotice?.affidavitDisabled
  );
};

export type PublishingDateMetadata = {
  numberOfNotices: number;
  timestamp: number;
  date: moment.Moment;
};

export const UPLOAD_CACHE_DATE_FORMAT = 'MM/DD/YYYY';

export const MANUAL_UPLOAD = 'Manual';
export const EMAIL_UPLOAD = 'Email';
export const FTP_UPLOAD = 'FTP';
export type UploadMethod =
  | typeof MANUAL_UPLOAD
  | typeof EMAIL_UPLOAD
  | typeof FTP_UPLOAD;

/**
 * Returns the upload method for a given PDF URL
 * @param url URL of the uploaded PDF
 * @returns upload method for the PDF
 */
export const affidavitUploadURLToUploadMethod = (url: string): UploadMethod => {
  if (url.includes('manual_')) return MANUAL_UPLOAD;
  if (url.includes('email_')) return EMAIL_UPLOAD;
  return FTP_UPLOAD;
};

/**
 * Takes in a file URL and returns the file name. It explicitly handles:
 * 1) URLs from Imgix
 *    https://enotice-production.imgix.net/affidavit_automation/e-edition-uploads/dog.pdf
 *    => dog.pdf
 * 2) URLs from cloudinary
 *    https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/affidavit_automation/e-edition-uploads/dog.pdf?invalidate=true
 *    => dog.pdf
 * @param url
 * @returns file name
 */
export const affidavitUploadURLToFileName = (url: string) => {
  const filePath = url.split('/').pop()!;
  const encodedSegment = filePath.split('%2F').pop()!;
  const cloudinaryHandledFile = encodedSegment.replace('?invalidate=true', '');
  const firebaseStorageHandledFile = cloudinaryHandledFile.split(
    '?alt=media&token'
  )[0];

  const manualPrefixHandledFile = firebaseStorageHandledFile.startsWith(
    'manual_'
  )
    ? firebaseStorageHandledFile.replace('manual_', '')
    : firebaseStorageHandledFile;
  const emailPrefixHandledFile = manualPrefixHandledFile.startsWith('email_')
    ? manualPrefixHandledFile.replace('email_', '')
    : manualPrefixHandledFile;
  return emailPrefixHandledFile;
};

export const eeditionUploadMethodToUploadMethodDescription = (
  uploadMethod: EEditionUploadMethod
) => {
  switch (uploadMethod) {
    case EEditionUploadMethod.MANUAL:
      return 'manually';
    case EEditionUploadMethod.FTP:
      return 'via FTP';
    case EEditionUploadMethod.EMAIL:
      return 'via email';
    case EEditionUploadMethod.SCRAPER:
      return 'via web scraper';
    default:
      return '';
  }
};

/**
 * These variables are used as default values for affidavits managed by Column.
 * IMPORTANT: if you make a change to DEFAULT_COLUMN_NOTARIZED_AFFIDAVIT_URL_STORAGE_PATH,
 * make sure that you also update DEFAULT_COLUMN_NOTARIZED_AFFIDAVIT_URL_STORAGE_URL.
 */
export const DEFAULT_COLUMN_NOTARIZED_AFFIDAVIT_STORAGE_PATH =
  'custom-documents/permalink/bd99.acfdd-sample_auto_affidavit.html';
export const DEFAULT_COLUMN_NOTARIZED_AFFIDAVIT_URL =
  'https://enotice-production.imgix.net/custom-documents/permalink/bd99.acfdd-sample_auto_affidavit.html';

/**
 * Takes in a set of affidavit reconciliation settings and determines whether or not
 * the affidavits are managed by column. These settings could be associated with a notice
 * or a publisher.
 * @param affidavitReconciliationSettings settings to check
 * @returns {boolean} yes if affidavits are managed by column, no if not
 */
const affidavitsAreManagedByColumnFromAffidavitReconciliationSettings = (
  affidavitReconciliationSettings:
    | AffidavitReconciliationSettings
    | undefined
    | null
): affidavitReconciliationSettings is AffidavitReconciliationSettings => {
  if (!affidavitReconciliationSettings) {
    return false;
  }

  const { affidavitsManagedByColumn } = affidavitReconciliationSettings;
  if (!affidavitsManagedByColumn) {
    return false;
  }

  return true;
};

/**
 * Takes in a notice with a set of affidavit reconciliation settings and determines what mail provider
 * should be used to send affidavits. These settings could be associated with a notice or a publisher.
 */
export const getMailProviderFromNoticeOrDraft = async (
  ctx: EFirebaseContext,
  noticeOrDraft: ESnapshotExists<ENotice> | ESnapshotExists<ENoticeDraft>
): Promise<MailDelivery['provider']> => {
  if (!noticeOrDraft.data().newspaper) {
    return 'lob';
  }

  const affidavitReconciliationSettings = await getAffidavitSettingsForNotice(
    ctx,
    noticeOrDraft
  );
  return affidavitReconciliationSettings?.notarizationVendor === 'manual'
    ? 'manual'
    : 'lob';
};

/**
 * Determines whether or not we are running automated affidavits for a publisher
 * @param organization Publisher we are checking
 * @returns {boolean} yes if we are running automated affidavits, no if not
 */
export const affidavitsAreManagedByColumn = (
  organization: ESnapshotExists<EOrganization>
) => {
  const { affidavitReconciliationSettings } = organization.data();
  return affidavitsAreManagedByColumnFromAffidavitReconciliationSettings(
    affidavitReconciliationSettings
  );
};

/**
 * Determines whether or not we are running automated affidavits for a notice
 * @param {EFirebaseContext} ctx context object
 * @param {ESnapshotExists<ENotice>} notice notice we are checking
 * @returns {boolean} true if we are running automated affidavits, false if not
 */
export const affidavitsAreManagedByColumnForNotice = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
) => {
  const affidavitReconciliationSettings = await getAffidavitSettingsForNotice(
    ctx,
    notice
  );
  return affidavitsAreManagedByColumnFromAffidavitReconciliationSettings(
    affidavitReconciliationSettings
  );
};

export const getNoticeFiltersWhereNoticesWerePublishedAndUseAffidavits = (
  reconciliationStartDate: FirebaseTimestamp | undefined,
  timezone: string
): SearchableNoticeRecordFilter[] => {
  /**
   * While Elastic's `from` filter is inclusive, the `to` filter is exclusive,
   * so we extend the range by 1 so that the notices publishing on the current
   * day are included.
   * see https://www.elastic.co/guide/en/elasticsearch/reference/current/search-aggregations-bucket-range-aggregation.html
   */
  const to =
    getPublicationTimestampForElasticQuery({
      dayString: getDateStringForDateInTimezone({
        date: moment().toDate(),
        timezone
      })
    }) + 1;
  const facetFilters: SearchableNoticeRecordFilter[] = [
    { iscancelled: Number(false) },
    { isdraft: Number(false) },
    { affidavitdisabled: [Number(false)] },
    {
      firstpublicationtimestamp: {
        to
      }
    }
  ];

  if (reconciliationStartDate) {
    facetFilters.push({
      lastpublicationtimestamp: {
        from: getPublicationTimestampForElasticQuery({
          dayString: getDateStringForDateInTimezone({
            date: reconciliationStartDate.toDate(),
            timezone
          })
        })
      }
    });
  }

  return facetFilters;
};

/**
 * Takes in a set of unverified notices and filters out cancelled notices
 * @param noticesNotValidated input set of notices, likely from an UploadDay object
 * @returns {ERef<ENotice>[]} set of notices that are not cancelled and exist within firestore
 */
export const excludeInvalidUnverifiedNotices = async (
  noticesNotValidated: ERef<ENotice>[],
  options: {
    publicationDate?: Date;
  }
) => {
  const noticesNotValidatedAndNulls = await Promise.all(
    noticesNotValidated.map(async noticeRef => {
      const notice = await noticeRef.get();
      if (!exists(notice)) return null;

      const { noticeStatus, publicationDates } = notice.data();
      if (
        [
          NoticeStatusType.cancelled.value,
          NoticeStatusType.affidavit_submitted.value
        ].includes(noticeStatus)
      )
        return null;

      if (options.publicationDate) {
        const utcPublicationDate = moment.utc(options.publicationDate);
        if (
          !publicationDates.some(pubDate =>
            moment(pubDate.toDate()).utc().isSame(utcPublicationDate, 'day')
          )
        ) {
          return null;
        }
      }

      const newspaper = await notice.data().newspaper.get();
      if (isAffidavitDisabled(notice.data(), newspaper)) return null;
      return notice;
    })
  );
  return noticesNotValidatedAndNulls.filter(isNotNull);
};

export const formatDeliveryAddress = (mailDelivery: MailDelivery) => {
  const { name, address } = mailDelivery;
  return `${name}, ${address.address_line1}, ${
    address.address_line2 ? `${address.address_line2}, ` : ''
  }${address.address_city}, ${State.by_value(
    address.address_state
  )?.abbrev?.toUpperCase()} ${address.address_zip}`;
};

export const getDigitalSigNotarizationNoticeFromNoticeAndPublication = ({
  noticeSnap,
  newspaperSnap
}: {
  noticeSnap: ESnapshotExists<
    Pick<ENotice, 'publicationDates' | 'affidavitReconciliationSettings'>
  >;
  newspaperSnap: ESnapshotExists<EOrganization>;
}): DigitalSigNotarizationNotice => {
  return {
    noticeId: noticeSnap.id,
    state: newspaperSnap.data().state,
    lastPublicationTimestamp: lastNoticePublicationDate(noticeSnap).getTime(),
    type: 'digital signature',
    requiresInStateNotary: !!noticeSnap.data().affidavitReconciliationSettings
      ?.requiresInStateNotary
  };
};

export const noticeSnapshotToDigitalSigNotarizationNotice = async (
  noticeSnap: ESnapshotExists<ENotice>
): Promise<DigitalSigNotarizationNotice> => {
  let newspaperSnap: ESnapshotExists<EOrganization>;
  try {
    newspaperSnap = await getOrThrow(noticeSnap.data().newspaper);
  } catch (err) {
    throw new Error(
      `Newspaper does not exist for notice ${noticeSnap.id}: ${err}`
    );
  }
  return getDigitalSigNotarizationNoticeFromNoticeAndPublication({
    noticeSnap,
    newspaperSnap
  });
};

export type FastWetSigConversionProps = {
  noticeSnap: ESnapshotExists<AAPendingQueryNotice>;
  newspaperSnap: ESnapshotExists<EOrganization>;
  aggregateDeliveryAddresses: MailDelivery[];
};

export const noticePublicationAndMailDataToFastWetSigNotarizationNotice = ({
  noticeSnap,
  newspaperSnap,
  aggregateDeliveryAddresses
}: FastWetSigConversionProps): FastWetSigNotarizationNotice => {
  const mailCenter = noticeSnap.data().affidavitReconciliationSettings
    ?.assignedMailCenter;
  const assignedToMailCenterAt = noticeSnap.data()
    .affidavitReconciliationSettings?.lastAssignedToNotarizationOrMailCenterAt;

  return {
    noticeId: noticeSnap.id,
    noticeName: `${noticeSnap.data().referenceId}`,
    state: newspaperSnap.data().state,
    type: 'wet signature',
    requiresInStateNotary: !!noticeSnap.data().affidavitReconciliationSettings
      ?.requiresInStateNotary,
    aggregateDeliveryAddresses,
    ...(mailCenter ? { mailCenter } : {}),
    ...(assignedToMailCenterAt
      ? { assignedToMailCenterAtMillis: assignedToMailCenterAt.toMillis() }
      : {}),
    affidavitStoragePath: noticeSnap.data().affidavit,
    affidavitLastUploadedAtMillis: noticeSnap
      .data()
      .affidavitLastUploadedAt?.toMillis()
  };
};

export const noticeSnapshotToFastWetSigNotarizationNotice = async (
  noticeSnap: ESnapshotExists<ENotice>
): Promise<FastWetSigNotarizationNotice> => {
  const newspaperSnap = await getOrThrow(noticeSnap.data().newspaper);
  const aggregateDeliveryAddresses = await getMailDataFromNoticeOrDraft(
    noticeSnap.ref
  );
  return noticePublicationAndMailDataToFastWetSigNotarizationNotice({
    noticeSnap,
    newspaperSnap,
    aggregateDeliveryAddresses
  });
};

export const getNoticeHasAllRelevantRuns = async (
  notice: ESnapshotExists<ENotice>,
  activeRuns: RunModel[]
): Promise<boolean> => {
  const { newspaper, publicationDates } = notice.data();
  if (!noticeIsSubmitted(notice)) {
    const activeRunsPresent = activeRuns.length > 0;
    if (activeRunsPresent) {
      getErrorReporter().logAndCaptureWarning(
        '[getNoticeHasAllRelevantRuns] - Unsubmitted notice has active runs',
        {
          noticeId: notice.id,
          runs: `${activeRuns.map(run => run.modelData.publicationDate)}`
        }
      );
      return false;
    }
    return true;
  }

  const newspaperSnap = await getOrThrow(newspaper);
  const { iana_timezone } = newspaperSnap.data();

  const publicationDateStrings = publicationDates.map(pubDate =>
    getDateStringForDateInTimezone({
      date: pubDate.toDate(),
      timezone: iana_timezone
    })
  );

  const allPubDatesAccountedFor =
    publicationDateStrings.length === activeRuns.length &&
    publicationDateStrings.every(pubDateString =>
      activeRuns.some(run => run.modelData.publicationDate === pubDateString)
    );

  return allPubDatesAccountedFor;
};

export const getRunsMissingVerificationForNotice = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
): Promise<RunModel[]> => {
  const noticeModel = getModelFromSnapshot(UserNoticeModel, ctx, notice);
  const runs = await noticeModel.getRuns();
  const noticeHasAllRelevantRuns = getNoticeHasAllRelevantRuns(notice, runs);
  if (!noticeHasAllRelevantRuns) {
    getErrorReporter().logAndCaptureWarning(
      '[getRunsMissingVerificationForNotice] - Notice does not have all relevant runs; cannot determine all missing verification dates'
    );
    return [];
  }

  return runs.filter(run => !run.isVerified());
};

export const getUnverifiedDatesForNotice = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
): Promise<string[]> => {
  const unverifiedRuns = await getRunsMissingVerificationForNotice(ctx, notice);

  return unverifiedRuns.map(run => run.modelData.publicationDate);
};

export const areAnyRunsUnverifiable = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
): Promise<boolean> => {
  const noticeModel = getModelFromSnapshot(UserNoticeModel, ctx, notice);
  const runs = await noticeModel.getRuns();
  return runs.some(run => run.isUnverifiable());
};

/**
 * This function determines if all of the publication runs for a notice have
 * been verified by Column.
 */
export const haveAllRunsBeenVerified = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
): Promise<boolean> => {
  const noticeModel = getModelFromSnapshot(UserNoticeModel, ctx, notice);
  if (!noticeIsSubmitted(notice)) {
    return false;
  }

  const runs = await noticeModel.getRuns();
  const allPresentRunsVerified = runs.every(run => run.isVerified());
  const noticeHasAllRelevantRuns = await getNoticeHasAllRelevantRuns(
    notice,
    runs
  );
  return allPresentRunsVerified && noticeHasAllRelevantRuns;
};

const getNoticePassesNotarizationPrecondition = async ({
  ctx,
  notice,
  notarizationPreconditionKey
}: {
  ctx: EFirebaseContext;
  notice: UserNoticeModel;
  notarizationPreconditionKey: NotarizationPreconditionKey;
}) => {
  const lastPubDate = lastNoticePublicationDate(notice);
  switch (notarizationPreconditionKey) {
    case 'notice_has_ARS': {
      return !!notice.modelData.affidavitReconciliationSettings;
    }
    case 'notice_has_invoice': {
      return !!notice.modelData.invoice;
    }
    case 'notice_is_pending': {
      return notice.modelData.noticeStatus === NoticeStatusType.pending.value;
    }
    case 'notice_does_not_have_affidavit': {
      return !notice.modelData.affidavit;
    }
    case 'reconciliation_start_date_is_before_last_publication_date': {
      /**
       * Note: we currently only check the notice-level affidavit settings instead of calling
       * `getAffidavitSettingsForNotice` in order to save on async calls to Firestore and
       * not further slow down the notices query for notarizations. This requires that notices
       * have accurate ARS in order to be automatically recognized as ready for notarization.
       */
      const { reconciliationStartDate } =
        notice.modelData.affidavitReconciliationSettings || {};

      if (!reconciliationStartDate) {
        return false;
      }

      const startDateMoment = moment(reconciliationStartDate.toDate());
      const lastPubDateMoment = moment(lastPubDate);

      return startDateMoment.isSameOrBefore(lastPubDateMoment, 'day');
    }
    case 'last_publication_date_is_past': {
      return moment(lastPubDate).startOf('day').isBefore(moment());
    }
    case 'affidavits_enabled': {
      return await notice.areAffidavitsEnabled();
    }
    case 'all_runs_verified': {
      return await haveAllRunsBeenVerified(ctx, notice);
    }
    default: {
      throw new Error(
        `Unmapped notarization precondition key ${notarizationPreconditionKey}`
      );
    }
  }
};

const getStatusOfNotarizationPrecondition = ({
  notarizationPrecondition,
  notice,
  noticePassesPrecondition
}: {
  notarizationPrecondition: NotarizationPreconditionEnum | undefined;
  notice: UserNoticeModel;
  noticePassesPrecondition: boolean;
}): NoticePreconditionStatus => {
  if (noticePassesPrecondition) {
    return 'passes';
  }

  if (
    notarizationPrecondition?.canBeOverridden &&
    notice.modelData.affidavitReconciliationSettings
      ?.overrideNotarizationPreconditions
  ) {
    return 'overridden';
  }

  return 'blocked';
};

export const getNoticePreconditionStatuses = async ({
  ctx,
  notice
}: {
  ctx: EFirebaseContext;
  notice: UserNoticeModel;
}): Promise<NoticePreconditionStatuses> => {
  const noticePreconditionStatuses: NoticePreconditionStatuses = {
    notice_has_ARS: 'blocked',
    notice_has_invoice: 'blocked',
    notice_is_pending: 'blocked',
    notice_does_not_have_affidavit: 'blocked',
    reconciliation_start_date_is_before_last_publication_date: 'blocked',
    last_publication_date_is_past: 'blocked',
    affidavits_enabled: 'blocked',
    all_runs_verified: 'blocked'
  };

  await Promise.all(
    NotarizationPrecondition.items().map(async notarizationPreconditionEnum => {
      const notarizationPreconditionKey = getTypedNotarizationPreconditionKey(
        notarizationPreconditionEnum.key
      );
      if (!notarizationPreconditionKey) {
        throw new Error(
          `Unrecognized notarization precondition key ${notarizationPreconditionEnum.key}`
        );
      }
      const noticePassesPrecondition = await getNoticePassesNotarizationPrecondition(
        {
          ctx,
          notice,
          notarizationPreconditionKey
        }
      );

      const noticePreconditionStatus = getStatusOfNotarizationPrecondition({
        notice,
        noticePassesPrecondition,
        notarizationPrecondition: NotarizationPrecondition.by_key(
          notarizationPreconditionKey
        )
      });

      noticePreconditionStatuses[
        notarizationPreconditionKey
      ] = noticePreconditionStatus;
    })
  );

  return noticePreconditionStatuses;
};

/**
 * This function takes in all of a given notice's individual precondition statuses and determines whether,
 * at the notice level, the notice will be included in the list of notices to notarize.
 *
 * If any precondition has a 'blocked' status, the notice will be 'blocked' and will not be included on the list.
 * If all of the preconditions have a 'passes' status, the notice will also have a 'passes' status and be included on the list.
 * If none of the preconditions is 'blocked', but some are 'overridden', then the notice will be 'overridden': it will be included
 * on the list with a warning.
 */
export const getAggregateNoticePreconditionStatus = (
  noticePreconditionStatuses: NoticePreconditionStatuses
): NoticePreconditionStatus => {
  if (
    Object.values(noticePreconditionStatuses).some(
      status => status === 'blocked'
    )
  ) {
    return 'blocked';
  }

  if (
    Object.values(noticePreconditionStatuses).every(
      status => status === 'passes'
    )
  ) {
    return 'passes';
  }

  return 'overridden';
};

// Default template used for legacy puppeteer-generated affidavits
export const DEFAULT_AFFIDAVIT_URL =
  'https://enotice-production.imgix.net/custom-documents/permalink/08e2.af9a2-affidavit_template.html';
// Default template that is used for PDFKit-generated affidavits
export const DEFAULT_AFFIDAVIT_URL_UPDATED =
  'https://enotice-production.imgix.net/custom-documents/permalink/1711464427321/standard_affidavit_block.html';
