/* eslint-disable no-await-in-loop */
import { useEffect, useMemo, useState } from 'react';
import ToastActions from 'redux/toast';
import {
  ESnapshotExists,
  EOrganization,
  EUser,
  exists,
  ESnapshot,
  ERequestTypes,
  EResponseTypes
} from 'lib/types';
import { logAndCaptureException } from 'utils';
import { TableLayout } from 'lib/components/TableLayout';
import { isPublisherOrganization } from 'lib/utils/organizations';
import ProcessPaymentModal from 'routes/notice/ProcessPaymentModal';
import ProcessCheckModal from 'routes/notice/ProcessCheckModal';
import SendReminderModal from 'routes/notice/SendReminderModal';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { FinancePaper } from 'lib/components/gifs';
import api from 'api';
import {
  SORT_DESCENDING,
  SearchableInvoiceRecord,
  SORT_ASCENDING
} from 'lib/types/searchable';
import { debounce } from 'lodash';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnService } from 'lib/services/directory';
import { useAppDispatch } from 'redux/hooks';
import { useSet } from 'lib/frontend/hooks/useSet';
import { isPublisher } from 'lib/utils/users';
import { INVOICE_STATUSES_UNPAID } from 'lib/model/objects/invoiceModel';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { InvoicesTableRow } from './InvoicesTableRow';
import InvoicesTableFilterDialog from './InvoicesTableFilterDialog';
import {
  ALL_INVOICE_CREATION_DATES,
  DEFAULT_INVOICE_STATUS_FILTER,
  getElasticFilters,
  InvoiceTableFilter
} from './invoiceTableSettingsUtils';
import { ProcessBulkPaymentModal } from './ProcessBulkPaymentModal';
import useShouldShowDownloadSummary from './hooks/useShouldShowDownloadSummary';

type InvoiceTableProps = {
  user: ESnapshotExists<EUser>;
  organization: ESnapshot<EOrganization> | null;
  usesBulkInvoiceV2: boolean;
};
const MAX_INVOICES_QUERY_SIZE = 1000;

/**
 * Determines the number of active items in the current filter for display in the UI
 * @param uploadFilter
 * @returns
 */
const getNumActiveFilters = (invoiceFilter: InvoiceTableFilter) => {
  let numActive = 0;

  if (invoiceFilter.dateRange !== ALL_INVOICE_CREATION_DATES) {
    numActive++;
  }

  if (
    invoiceFilter.invoiceTableStatus !==
    DEFAULT_INVOICE_STATUS_FILTER.invoiceTableStatus
  ) {
    numActive++;
  }

  return numActive;
};

export const downloadUnpaidInvoicesSummary = async (organizationId: string) => {
  const { response, error } = await api.safePost(
    `documents/unpaid-invoices-summary/download`,
    {
      advertiserOrganizationId: organizationId
    }
  );

  if (error) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      error,
      'Failed to download unpaid invoices sumamry',
      { organizationId }
    );
    return '';
  }

  return response;
};

export const downloadInvoices = async (invoices: SearchableInvoiceRecord[]) => {
  const request: ERequestTypes['documents/invoices/download'] = {
    invoiceIds: invoices.map(invoice => invoice.id)
  };
  try {
    const { response, error } = await api.safePost(
      `documents/invoices/download`,
      request
    );
    if (response) {
      return wrapSuccess(response);
    }

    throw error;
  } catch (err) {
    getErrorReporter().logAndCaptureCriticalError(
      ColumnService.PAYMENTS,
      err,
      'Failed to download invoices'
    );

    return wrapError(err as Error);
  }
};

const PAGE_SIZE = 10;

export type InvoiceFilerData = {
  [id: string]: { name: string; orgName?: string };
};

/**
 * Wrapper component for the Invoices table page.
 */
export default function InvoiceTable({
  user,
  organization,
  usesBulkInvoiceV2
}: InvoiceTableProps) {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [
    processPaymentModalInvoice,
    setProcessPaymentModalInvoice
  ] = useState<SearchableInvoiceRecord>();
  const [
    processCheckModalInvoice,
    setProcessCheckModalInvoice
  ] = useState<SearchableInvoiceRecord>();
  const [sendReminderModalInvoice, setSendReminderModalInvoice] = useState<
    SearchableInvoiceRecord | false
  >();

  const shouldAllowBulkPaymentFlag = useBooleanFlag(
    LaunchDarklyFlags.SHOW_BULK_PAYMENT_IN_INVOICES_TABLE,
    false
  );
  const shouldAllowBulkPayment =
    shouldAllowBulkPaymentFlag && !isPublisher(user);
  const [selectedInvoices, selectedInvoiceActions] = useSet<string>();
  const [
    processBulkPaymentModalInvoice,
    setProcessBulkPaymentModalInvoice
  ] = useState<SearchableInvoiceRecord[]>();
  const [downloadSummaryLoading, setDownloadSummaryLoading] = useState(false);

  // current value of the search bar on the invoice table
  const [invoiceFilter, setInvoiceFilter] = useState(
    DEFAULT_INVOICE_STATUS_FILTER
  );

  const [updatedInvoiceFilter, setUpdatedInvoiceFilter] = useState(
    invoiceFilter
  );

  const updateSearch = useMemo(
    () =>
      debounce(value => {
        setDebouncedSearchTerm(value);
      }, 400),
    []
  );

  // columnObjectsAreEqual does not work with comparing date ranges
  // of this type, so we have to do a separate comparison
  const filterHasChanges =
    !columnObjectsAreEqual(updatedInvoiceFilter, invoiceFilter) ||
    updatedInvoiceFilter.dateRange !== invoiceFilter.dateRange;

  const loadInvoices = async () => {
    try {
      const filters = getElasticFilters(user, organization, invoiceFilter);

      const postBody: ERequestTypes['search/invoices'] = {
        search: debouncedSearchTerm,
        sort: [
          { paid: SORT_ASCENDING },
          { voided: SORT_ASCENDING },
          { duedate: SORT_DESCENDING }
        ],
        allFilters: filters
      };

      const response = (await api.post(
        'search/invoices',
        postBody
      )) as EResponseTypes['search/invoices'];

      if (response.error) {
        return null;
      }

      return response.results;
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Failed to load invoices',
        {
          userEmail: user.data().email,
          organizationName: organization?.data()?.name || ''
        }
      );
      return null;
    }
  };

  const { value: invoices, invalidateData, isLoading } = useAsyncEffect({
    fetchData: loadInvoices,
    dependencies: [
      user.id,
      organization?.id,
      JSON.stringify(invoiceFilter),
      debouncedSearchTerm
    ]
  });

  useEffect(() => {
    updateSearch(searchTerm);
  }, [searchTerm]);

  const { hasUnpaidInvoices } = useShouldShowDownloadSummary(
    user,
    organization
  );

  const handleDownloadInvoicesClick = async () => {
    if (!invoices?.length) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Failed to download invoices',
          bodyText: 'There are no invoices to download.'
        })
      );
      return;
    }

    const {
      response: downloadURL,
      error: downloadError
    } = await downloadInvoices(invoices ?? []);
    if (downloadError) {
      dispatch(
        ToastActions.toastError({
          headerText: 'Failed to download invoices',
          bodyText:
            'Our team has been notified and we will look into the issue immediately. Please try again later.'
        })
      );
      return;
    }

    window.open(downloadURL, '_blank');
    dispatch(
      ToastActions.toastSuccess({
        headerText: 'Invoices downloaded',
        bodyText:
          "Please check your download folder to view the invoices. If you don't see the file, make sure that you have downloads enabled for column.us."
      })
    );
  };

  // TODO: In a tech debt sprint, let's separate out the advertiser invoice table and the publisher invoice table
  const advertiserColumns = [
    <th key="date-created" className="font-medium w-56">
      Date Created
    </th>,
    <th key="invoice-id" className="font-medium w-56">
      Invoice ID
    </th>,
    <th key="notice-name" className="font-medium w-56">
      Notice Name
    </th>,
    <th key="publisher" className="font-medium w-56">
      Publisher
    </th>,
    <th key="amount" className="font-medium w-56">
      Amount
    </th>,
    <th key="status" className="font-medium w-56">
      Status
    </th>,
    <th key="due-date" className="font-medium w-56">
      Due Date
    </th>,
    <th key="actions" className="font-medium w-40">
      Actions
    </th>
  ];

  const advertiserColumnsForBulkInvoices = [
    <th key="date-created" className="font-medium w-56">
      Date Created
    </th>,
    <th key="invoice-id" className="font-medium w-56">
      Invoice ID
    </th>,
    <th key="publisher" className="font-medium w-56">
      Publisher
    </th>,
    <th key="amount" className="font-medium w-56">
      Amount
    </th>,
    <th key="status" className="font-medium w-56">
      Status
    </th>,
    <th key="due-date" className="font-medium w-56">
      Due Date
    </th>,
    <th key="actions" className="font-medium w-56">
      Actions
    </th>
  ];

  const publisherColumns = [
    <th key="date-created" className="font-medium w-44">
      Name
    </th>,
    <th key="notice-name" className="font-medium w-56">
      Notice Name
    </th>,
    <th key="invoice-id" className="font-medium w-44">
      Organization
    </th>,
    <th key="amount" className="font-medium w-44">
      Date Created
    </th>,
    <th key="status" className="font-medium w-44">
      Amount
    </th>,
    <th key="due-date" className="font-medium w-44">
      Status
    </th>,
    <th key="actions" className="font-medium w-44">
      Due Date
    </th>,
    <th key="actions" className="font-medium w-44">
      <div className="pl-10">Actions</div>
    </th>
  ];
  const publisherColumnsForBulkInvoices = [
    <th key="date-created" className="font-medium w-44">
      Name
    </th>,
    <th key="invoice-id" className="font-medium w-44">
      Organization
    </th>,
    <th key="amount" className="font-medium w-44">
      Date Created
    </th>,
    <th key="status" className="font-medium w-44">
      Amount
    </th>,
    <th key="due-date" className="font-medium w-44">
      Status
    </th>,
    <th key="actions" className="font-medium w-44">
      Due Date
    </th>,
    <th key="actions" className="font-medium w-44">
      <div className="pl-10">Actions</div>
    </th>
  ];
  const title = `Total invoices: ${invoices?.length ?? 0}${
    invoices?.length === MAX_INVOICES_QUERY_SIZE ? '+' : ''
  }`;

  return (
    <div className="pb-10 py-4 px-8 m-4">
      <div className="flex justify-between pb-6 items-center">
        <h1 className="text-2xl font-medium leading-tight text-column-gray-900 mb-1">
          Invoices
        </h1>
      </div>

      <div
        id="invoices-settings"
        className="bg-white rounded-lg border border-column-gray-100 mb-24 shadow-column-2"
      >
        <TableLayout
          header={{
            subtitle: `Track all Column invoices for your notices.`,
            title
          }}
          columns={
            isPublisherOrganization(organization)
              ? usesBulkInvoiceV2
                ? publisherColumnsForBulkInvoices
                : publisherColumns
              : usesBulkInvoiceV2
              ? advertiserColumnsForBulkInvoices
              : advertiserColumns
          }
          renderRow={invoice => (
            <InvoicesTableRow
              invoice={invoice}
              organization={organization}
              setSendReminderModalInvoice={setSendReminderModalInvoice}
              setProcessPaymentModalInvoice={setProcessPaymentModalInvoice}
              usesBulkInvoiceV2={usesBulkInvoiceV2}
            />
          )}
          noResultsContent={
            invoices?.length === 0
              ? {
                  header: 'No invoices yet',
                  subheader: usesBulkInvoiceV2
                    ? 'Invoices will appear here after your first month of bulk payments activities.'
                    : '',
                  icon: <img src={FinancePaper} />
                }
              : undefined
          }
          data={invoices || []}
          loading={isLoading}
          id="invoices"
          filterable={{
            shouldShowTableItem: () => {
              return true;
            },
            additionalFilters: {
              applyFilterChanges: () => setInvoiceFilter(updatedInvoiceFilter),
              resetFilters: () => {
                setUpdatedInvoiceFilter(DEFAULT_INVOICE_STATUS_FILTER);
                setInvoiceFilter(DEFAULT_INVOICE_STATUS_FILTER);
              },
              filterHasChanges,
              numFiltersActive: getNumActiveFilters(invoiceFilter),
              renderDialog: () => (
                <InvoicesTableFilterDialog
                  setUpdatedInvoiceFilter={setUpdatedInvoiceFilter}
                  updatedInvoiceTableFilter={updatedInvoiceFilter}
                  enableInvoiceTypeFiltering={usesBulkInvoiceV2}
                />
              )
            },
            onSearch: (searchTerm: string) => {
              setSearchTerm(searchTerm);
            }
          }}
          pagination={{ pageSize: PAGE_SIZE }}
          downloadable={{
            buttonText: 'Download Invoices',
            onClickDownload: handleDownloadInvoicesClick
          }}
          actionable={
            <>
              {hasUnpaidInvoices && (
                <ColumnButton
                  buttonText="Download Summary"
                  size="sm"
                  type="button"
                  loading={downloadSummaryLoading}
                  onClick={async () => {
                    setDownloadSummaryLoading(true);
                    const url = await downloadUnpaidInvoicesSummary(
                      organization?.id || ''
                    );
                    if (url) {
                      window.open(url, '_blank');
                    }
                    setDownloadSummaryLoading(false);
                  }}
                />
              )}
              {selectedInvoices.size > 1 && (
                <ColumnButton
                  primary
                  buttonText="Pay Selected Invoices"
                  size="sm"
                  onClick={() =>
                    setProcessBulkPaymentModalInvoice(
                      invoices?.filter(invoice =>
                        selectedInvoices.has(invoice.id)
                      )
                    )
                  }
                  startIcon={
                    <CreditCardIcon className="w-5 py-1 text-column-grey-400" />
                  }
                  type="button"
                />
              )}
            </>
          }
          selectable={
            shouldAllowBulkPayment
              ? {
                  onSelectItems: (items, checked) =>
                    items.forEach(item =>
                      checked
                        ? selectedInvoiceActions.add(item.id)
                        : selectedInvoiceActions.remove(item.id)
                    ),
                  selectedItems: selectedInvoices,
                  serializeItemToUniqueId: item => item.id,
                  isSelectDisabled: item => {
                    if (item.voided) {
                      return true;
                    }

                    const isUnpaid = INVOICE_STATUSES_UNPAID.includes(
                      item.status
                    );

                    if (!isUnpaid) {
                      return true;
                    }

                    return false;
                  }
                }
              : undefined
          }
        />
      </div>
      {exists(organization) && isPublisherOrganization(organization) && (
        <>
          {processPaymentModalInvoice && (
            <ProcessPaymentModal
              setOpen={() => setProcessPaymentModalInvoice(undefined)}
              setProcessCheck={() =>
                setProcessCheckModalInvoice(processPaymentModalInvoice)
              }
              invoiceId={processPaymentModalInvoice.id}
              newspaper={organization}
            />
          )}
          {processCheckModalInvoice && (
            <ProcessCheckModal
              setOpen={() => setProcessCheckModalInvoice(undefined)}
              invoiceId={processCheckModalInvoice.id}
              advertiserId={processCheckModalInvoice.filerid}
              newspaper={organization}
            />
          )}
          {sendReminderModalInvoice && (
            <SendReminderModal
              invoiceId={sendReminderModalInvoice.id}
              newspaper={organization}
              onCloseModal={() => setSendReminderModalInvoice(false)}
            />
          )}
        </>
      )}
      {!!processBulkPaymentModalInvoice?.length && (
        <ProcessBulkPaymentModal
          onClose={() => {
            setProcessBulkPaymentModalInvoice(undefined);
            selectedInvoiceActions.clear();
            invalidateData();
          }}
          invoices={processBulkPaymentModalInvoice ?? []}
        />
      )}
    </div>
  );
}
