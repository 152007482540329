import { NewspaperOrder } from 'lib/types/newspaperOrder';
import { ColorSettingVisibility } from '../../colorHelpers';
import EditorToolbar from '../EditorToolbar';
import BackgroundColorButton from './BackgroundColorButton';

type GlobalEditorToolbarProps = Pick<
  ColorSettingVisibility,
  'showBackgroundColor'
> & {
  colorOptions: Required<NewspaperOrder>['colorOptions'];
  onChange: (newColorOptions: Required<NewspaperOrder>['colorOptions']) => void;
};

function GlobalEditorToolbar({
  showBackgroundColor,
  colorOptions,
  onChange
}: GlobalEditorToolbarProps) {
  return (
    <EditorToolbar>
      <div className="flex px-1 py-px">
        {showBackgroundColor && (
          <BackgroundColorButton
            value={colorOptions.backgroundColor || 'transparent'}
            onChange={newValue => {
              onChange({ ...colorOptions, backgroundColor: newValue });
            }}
          />
        )}
      </div>
    </EditorToolbar>
  );
}

export default GlobalEditorToolbar;
