import { Badge } from 'lib/components/Badge';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { SearchableOrderRecord } from 'lib/types/searchable';
import moment from 'moment';
import { ReactElement } from 'react';
import { capitalizeWord } from 'lib/utils/strings';
import { NoSymbolIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { logAndCaptureMessage } from 'utils';
import { capitalize } from 'lodash';
import { AdsTableRowPublicationDateColumn } from './AdsTableRowPublicationDateColumn';
import {
  CustomerTypeString,
  VerificationFilterString
} from './filters/helpers';
import { getBadgeStatusForNewspaperOrderStatus } from './helpers';
import { AdsTableColumns } from './types';

type AdsTableRowProps<T extends SearchableOrderRecord> = {
  item: T;
  columns: AdsTableColumns[];
};

export default function AdsTableRow<T extends SearchableOrderRecord>({
  item,
  columns
}: AdsTableRowProps<T>) {
  const searchableOrderRecordToFormattedAd = (
    ad: T
  ): Record<string, string | ReactElement | undefined> => {
    const deceasedName = ad.deceasedname;
    // Since we haven'd defined classified props yet, the field we use for the listing name may change
    const listingName = ad.title;
    const adDeadline = (
      <Badge>{moment(Number(ad.addeadline)).format('MMM D, YYYY')}</Badge>
    );
    // if the obit was placed by a funeral home, we want to show that funeral home as the customer
    // if the ad was placed by an individual, the funeral home name does not refer to the customer,
    // (i.e., the person paying for the order) so we want to use the filer's name instead
    // TODO: (Zubaria) show here classified customer name (if customer exists)
    const customerName = ad.hasadvertiserorg
      ? ad.funeralhomename ?? ''
      : ad.filerfirstname && ad.filerlastname
      ? `${ad.filerfirstname} ${ad.filerlastname}`
      : '';
    const category = (
      <div className="flex gap-2">
        <Badge>{ad.filingtype}</Badge>
        {ad.publishingmedium && (
          <Badge>{capitalize(ad.publishingmedium)}</Badge>
        )}
      </div>
    );
    const publicationDates = (
      <AdsTableRowPublicationDateColumn publishingdates={ad.publishingdates} />
    );
    const customerType = (
      <Badge>
        {ad.hasadvertiserorg
          ? CustomerTypeString.FUNERAL_HOME
          : CustomerTypeString.INDIVIDUAL}
      </Badge>
    );
    const verification = (
      <Badge
        startIcon={
          ad.isverified ? (
            <CheckCircleIcon className="w-3 h-3" />
          ) : (
            <NoSymbolIcon className="w-3 h-3" />
          )
        }
      >
        {ad.isverified
          ? VerificationFilterString.COMPLETE
          : VerificationFilterString.INCOMPLETE}
      </Badge>
    );
    const publication = <Badge>{ad.publishername}</Badge>;
    const status = (
      <Badge
        status={getBadgeStatusForNewspaperOrderStatus(
          ad.newspaperorderstatus as NewspaperOrderStatus
        )}
      >
        {capitalizeWord(ad.newspaperorderstatus)}
      </Badge>
    );

    const newspaperName = ad.publishername;

    const row = columns.reduce((accRow, column) => {
      switch (column) {
        case AdsTableColumns.DECEASED_NAME:
          return { ...accRow, deceasedName };
        case AdsTableColumns.AD_DEADLINE:
          return { ...accRow, adDeadline };
        case AdsTableColumns.CUSTOMER_NAME:
          return { ...accRow, customerName };
        case AdsTableColumns.NEWSPAPER_NAME:
          return { ...accRow, newspaperName };
        case AdsTableColumns.CATEGORY:
          return { ...accRow, category };
        case AdsTableColumns.PUBLICATION_DATE:
          return { ...accRow, publicationDates };
        case AdsTableColumns.CUSTOMER_TYPE:
          return { ...accRow, customerType };
        case AdsTableColumns.VERIFICATION:
          return { ...accRow, verification };
        case AdsTableColumns.PUBLICATION:
          return { ...accRow, publication };
        case AdsTableColumns.STATUS:
          return { ...accRow, status };
        case AdsTableColumns.LISTING_NAME:
          return { ...accRow, listingName };
        default:
          logAndCaptureMessage('Unexpected column in ads table', { column });
          return accRow;
      }
    }, {});
    return row;
  };

  // Construct table rows data
  const formattedItem = searchableOrderRecordToFormattedAd(item);

  return (
    <>
      {Object.values(formattedItem).map((value, index) => (
        <td key={index}>{value ?? ''}</td>
      ))}
    </>
  );
}
