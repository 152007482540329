import { ERequestTypes, ESnapshot, EUser } from 'lib/types';
import {
  PayInvoiceData,
  InvoicePricingData,
  PaymentMethods,
  InvoiceType,
  isPayOrderInvoiceData
} from 'lib/types/invoices';
import { logAndCaptureMessage } from 'utils';
import { apiPost } from 'api/typed';
import { Product } from 'lib/enums';
import { PayInvoicePaymentMethodsType } from '../PayInvoice';

const getPaymentMethodFromInvoicePaymentMethod = (
  invoicePaymentMethod: PayInvoicePaymentMethodsType
): PaymentMethods => {
  switch (invoicePaymentMethod) {
    case 'saved-ach':
      return 'ach';
    case 'saved-bank':
      return 'ach';
    case 'saved-card':
      return 'card';
    case 'card':
      return 'card';
  }
};

export const processStripeAuthorizationWithCard = async (
  paymentMethodId: string,
  paymentMethod: PaymentMethods,
  payInvoiceData: PayInvoiceData,
  invoicePricingData: InvoicePricingData,
  idempotencyKey: string
): Promise<void> => {
  if (payInvoiceData.type === InvoiceType.ORDER) {
    return;
  }
  const { invoice, advertiser } = payInvoiceData;
  const customerEmail = advertiser.modelData.email;
  if (!customerEmail) {
    logAndCaptureMessage('Customer email missing in processPaymentWithCard!', {
      invoiceId: invoice.id,
      advertiserId: advertiser?.id
    });
    return;
  }

  const result = await apiPost('invoice-transactions/create-authorization', {
    amountInCents: invoicePricingData.totalAmount,
    customerEmail,
    invoiceId: invoice.id,
    paymentMethodType: paymentMethod,
    paymentMethodToken: paymentMethodId,
    idempotencyKey
  });

  if (!result.gatewayResultSuccessful) {
    throw result.responseMessage;
  }
};

export const processStripePaymentWithCard = async (
  paymentMethodId: string,
  paymentMethod: PaymentMethods,
  payInvoiceData: PayInvoiceData,
  invoicePricingData: InvoicePricingData,
  saveCreditCard: boolean
): Promise<void> => {
  const { invoice } = payInvoiceData;
  let customerEmail: string;
  const isOrderInvoice = isPayOrderInvoiceData(payInvoiceData);

  if (isOrderInvoice) {
    customerEmail = payInvoiceData.customerEmail;
  } else {
    const { advertiser } = payInvoiceData;
    customerEmail = advertiser.modelData.email;
  }
  if (!customerEmail) {
    logAndCaptureMessage('Customer email missing in processPaymentWithCard!', {
      invoiceId: invoice.id
    });
    return;
  }

  const result = await apiPost('payments/pay-stripe-invoice', {
    amount: invoicePricingData.totalAmount,
    customerEmail,
    invoiceId: invoice.id,
    paymentMethod,
    paymentMethodId,
    saveCreditCard,
    isOrder: isOrderInvoice,
    orderVersion: isOrderInvoice ? payInvoiceData.orderVersion : undefined,
    product: isOrderInvoice ? payInvoiceData.product : Product.Notice
  });

  if (!result.success) {
    throw result.error;
  }
};

export const processStripePaymentWithSavedMethod = async (
  invoicePaymentMethod: PayInvoicePaymentMethodsType,
  selectedSavedMethodId: string,
  payInvoiceData: PayInvoiceData,
  invoicePricingData: InvoicePricingData,
  user: ESnapshot<EUser> | null
): Promise<void> => {
  const isOrderInvoice = isPayOrderInvoiceData(payInvoiceData);
  if (!invoicePaymentMethod.startsWith('saved-') || !user) return;
  const paymentMethod = getPaymentMethodFromInvoicePaymentMethod(
    invoicePaymentMethod
  );
  const { invoice, advertiser } = payInvoiceData;
  const customerEmail = advertiser?.data().email;
  if (!customerEmail) {
    logAndCaptureMessage(
      'Customer email missing in processPaymentWithSavedMethod!',
      {
        invoiceId: invoice.id,
        advertiserId: advertiser?.id
      }
    );
    return;
  }

  let req: ERequestTypes['payments/pay-stripe-invoice'];
  const initialRequest = {
    amount: invoicePricingData.totalAmount,
    customerEmail,
    invoiceId: invoice.id,
    paymentMethod,
    isSavedPaymentMethod: true,
    orderVersion: isOrderInvoice ? payInvoiceData.orderVersion : undefined,
    product: isOrderInvoice ? payInvoiceData.product : Product.Notice
  };
  if (invoicePaymentMethod === 'saved-ach') {
    req = {
      ...initialRequest,
      userId: user?.id,
      stripeBankAccountSourceId: selectedSavedMethodId
    };
  } else {
    req = {
      ...initialRequest,
      paymentMethodId: selectedSavedMethodId
    };
  }

  const result = await apiPost('payments/pay-stripe-invoice', req);

  if (!result.success) {
    throw result.error;
  }
};
