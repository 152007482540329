export enum Product {
  Notice = 'notice',
  Obituary = 'obituary',
  Classified = 'classified'
}

export const isValidProduct = (product: string): product is Product =>
  Object.values(Product).includes(product as Product);

export const PRODUCT_TO_NAME: Record<
  Product,
  { singular: string; plural: string }
> = {
  [Product.Notice]: { singular: 'Public Notice', plural: 'Public Notices' },
  [Product.Classified]: { singular: 'Classified', plural: 'Classifieds' },
  [Product.Obituary]: { singular: 'Obituary', plural: 'Obituaries' }
};

export const isNoticeProduct = (product: string | undefined): boolean =>
  product === Product.Notice;
export const isObitProduct = (product: string | undefined): boolean =>
  product === Product.Obituary;
export const isClassifiedProduct = (product: string | undefined): boolean =>
  product === Product.Classified;

export const getProductTypeBooleans = (product: string | undefined) => {
  return {
    isNoticeProduct: isNoticeProduct(product),
    isObitProduct: isObitProduct(product),
    isClassifiedProduct: isClassifiedProduct(product)
  };
};
